<template>
  <div class="page">
    <div class="top">
      <img class="img" src="../../static/community/banner.png" />
    </div>
    <div class="content">
      <div class="left">
        <div class="tabs">
          <b-link
            class="link"
            v-for="(item, index) in types"
            :key="index"
            :class="current == index ? 'active' : ''"
            @click="changeCurrent(index)"
            >{{ item.title }}</b-link
          >
        </div>
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in list"
            :key="index"
            @click="toInfo(item)"
          >
            <div class="list-left">
              <video
                style="width: 150px; height: auto"
                v-if="item.src.indexOf('.mp4') > -1"
                :src="$host + item.src"
                poster="../../static/community/fm2.png"
              ></video>
              <img v-else :src="$host + item.src" class="tumb" />
            </div>
            <div class="list-right">
              <div class="name">
                <img class="avatar" src="../../static/community/photo1.png" />
                <span class="nickname">{{
                  item.user_info ? item.user_info.nickname : ""
                }}</span>
                <span class="time">{{ $timeFormat(item.createtime) }}</span>
              </div>
              <div class="list-title">{{ item.title }}</div>
              <div class="span">
                {{ item.content }}
              </div>
              <div class="list-bottom">
                <b-link class="icons">
                  <b-icon
                    icon="heart"
                    style="margin-right: 5px; font-size: 14px"
                  ></b-icon>
                  <span>{{ item.like_count }}</span>
                </b-link>
                <b-link class="icons">
                  <b-icon
                    icon="chat-dots"
                    style="margin-right: 5px; font-size: 14px"
                  ></b-icon>
                  <span>{{ item.comment_count }}</span>
                </b-link>
                <!-- <b-link class="icons">
                  <b-icon
                    icon="box-arrow-up-right"
                    style="margin-right: 5px; font-size: 14px"
                  ></b-icon>
                  <span>{{ item.share_count }}</span>
                </b-link> -->
              </div>
            </div>
          </div>
        </div>
        <div v-show="total == 0"><Empty></Empty></div>

        <b-pagination
          v-show="total > limit"
          style="margin: 20px 0"
          v-model="page"
          :total-rows="total"
          :per-page="limit"  
        ></b-pagination>
      </div>
      <div class="right">
        <div class="border-card">
          <div class="border-title">美蜂整形咨询师</div>
          <div class="card-content">
            <img
              src="../../static/community/img_girl.png"
              style="width: 110px; margin-left: 20px; height: auto"
            />
            <div style="margin-left: 10px">
              <div class="border-card-title">立即了解</div>
              <div class="border-card-desc">适合自身的变美方案</div>
              <div class="border-card-consult">我要咨询</div>
            </div>
          </div>
        </div>
        <div class="recom">
          <div class="recom-title">推荐医生</div>
          <div class="recom-list" style="margin-top: 10px">
            <div
              class="recom-item"
              v-for="(doctor, index) in doctors"
              :key="index"
              @click="toDoctor(doctor.id)"
            >
              <div style="width: 60px">
                <img class="recom-img" :src="$host + doctor.avatar" />
              </div>
              <div style="width: 240px">
                <div class="recom-name">{{ doctor.nickname }}</div>
                <div class="recom-desc">
                  {{ doctor.position ? doctor.position.name : "未认证" }}
                </div>
                <div style="font-size: 14px; height: 24px; line-height: 24px">
                  <b-icon style="color: #fd9852" icon="star-fill"></b-icon>
                  <b-icon style="color: #fd9852" icon="star-fill"></b-icon>
                  <b-icon style="color: #fd9852" icon="star-fill"></b-icon>
                  <b-icon style="color: #fd9852" icon="star-fill"></b-icon>
                  <b-icon style="color: #fd9852" icon="star-fill"></b-icon>
                  <span style="margin-left: 20px">
                    好评： {{ doctor.favorable_rate }}%
                  </span>
                </div>
                <div class="recom-like">
                  擅长：<span style="color: #999">{{ doctor.selfthing }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      types: [
        {
          id: "is_recom",
          title: "推荐",
        },
        // {
        //   title: "视频",
        //   id: "video",
        // },
      ],
      current: 0,
      cates: [],
      limit: 5,
      page: 1,
      total: 0,
      list: [],
      doctors: [],
    };
  },
  mounted() {
    this.getCates();
    this.getList();
    this.getDoctors();
  },
  watch: {
    page: function () {
      this.getList();
    },
    current: function () {
      this.page = 1;
      this.getList();
    },
  },
  methods: {
    changeTabs(index) {
      this.active = index;
    },
    changeCurrent(index) {
      if (this.types[index].id != "shop") {
        this.current = index;
      } else {
        this.$router.push({
          path: "/shop"
        })
      }
    },
    getCates() {
      this.$http.cate({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          data.forEach((item) => {
            this.types.push({
              title: item.title,
              id: item.id,
            });
          });
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getList() {
      let data = {
        science_cate_id: this.types[this.current].id,
        search: "",
        limit: this.limit,
        page: this.page, 
      };
      this.$http.listcontent(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          // this.list = data.items;
          data.items.forEach((item) => {
            item.src = item.resource.split(",")[0];
          });
          this.list = data.items;
          this.total = data.total;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getDoctors() {
      let data = {
        limit: 5,
        page: 1,
      };
      this.$http.doctors(data).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.doctors = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    toInfo(item) {
      this.$router.push({
        path: "/article",
        query: {
          id: item.id,
        },
      });
    },
    toDoctor(id) {
      this.$router.push({ path: "/doctor_info", query: { id: id } });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./community.scss";
</style>
